
	const query = new URLSearchParams(window.location.search);
	const myuid = query.get('myuid');
	const touid = query.get('touid');
	const mynickname = query.get('mynickname');
	const tonickname = query.get('tonickname');
	console.log('mynickname', mynickname)
	import { defineComponent, reactive, watchEffect, toRefs, ref, getCurrentInstance } from 'vue';
	import { ElMessage } from 'element-plus'

	const Custom = defineComponent({
		props: {
			show: {
				type: Boolean,
				default: () => false,
			},
		},
		setup(props : any, ctx : any) {

			//通过getCurrentInstance获取axios
			const { proxy } = getCurrentInstance() as any;

			let value1 = ref<[Date, Date]>([
				new Date(),
				new Date(),
			])

			const disabledDateFun = (time : Record<string, any>) : boolean => {
				if (time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000) {
					return true;
				} else {
					return false;
				}
			}

			const timeFilter = (timeStr : any) => {
				const day = timeStr.getDate().toString().padStart(2, '0');
				const month = (timeStr.getMonth() + 1).toString().padStart(2, '0'); // 月份是从0开始的
				const year = timeStr.getFullYear();
				const hours = timeStr.getHours().toString().padStart(2, '0');
				const minutes = timeStr.getMinutes().toString().padStart(2, '0');
				return `${year}-${month}-${day} ${hours}:${minutes}`;
			}

			const timeFilter1 = (timeStr : any) => {
				const day = timeStr.getDate().toString().padStart(2, '0');
				const month = (timeStr.getMonth() + 1).toString().padStart(2, '0'); // 月份是从0开始的
				const year = timeStr.getFullYear();
				const hours = timeStr.getHours().toString().padStart(2, '0');
				const minutes = timeStr.getMinutes().toString().padStart(2, '0');
				return `${year}/${month}/${day} ${hours}:${minutes}`;
			}


			const data = reactive({
				show: false,
				custom: {
					data: {
						businessID: "",
						text: "",
						link: "",
						time: '',
						content: '',
						myuid: myuid,
						touid: touid,
						interview_id: ''
					},
					description: '',
					extension: ''
				},
				TUIServer: null,
				myuid: myuid,
				touid: touid,
				mynickname: mynickname,
				interviewCount: 0,
				interviewMins: 0
			});

			data.TUIServer = Custom.TUIServer;

			if (!data.touid) {
				data.touid = Custom.TUIServer.store.conversation.userProfile.userID
			}

			watchEffect(() => {
				data.show = props.show;
			});

			const toggleShow = () => {
				if (!data.show) {
					proxy.$http.get(`/index.php/Home/CompanyUser/getInterviewNum?uid=${data.touid}&invite_uid=${data.myuid}`).then((res : any) => {
						data.show = !data.show;
						data.interviewCount = res.data.data.count;
						if (res.data.data.mins && res.data.data.mins > 0) {
							data.interviewMins = res.data.data.mins;
						}
					})
				} else {
					data.show = !data.show;
				}
			};

			const cancel = () => {
				data.custom = {
					data: {
						businessID: "text_link",
						text: "",
						link: "",
						time: "",
						content: "",
						myuid: "",
						touid: "",
						interview_id: ""
					},
					// data:'',
					description: '',
					extension: '',
				};
				toggleShow();
			};

			const submit = () => {
				const startTimeStr = timeFilter(value1.value[0]);
				const endTimeStr = timeFilter(value1.value[1]);

				const startTimeStr1 = timeFilter1(value1.value[0]);
				const endTimeStr1 = timeFilter1(value1.value[1]);

				let timeDif = value1.value[1].getTime() - value1.value[0].getTime();

				if (value1.value[0].getTime() < new Date().getTime()) {
					ElMessage.error('您选择的开始时间应大于当前时间！')
					return
				}

				if (timeDif == 0 || timeDif < 0) {
					ElMessage.error('您选择的面试结束时间需要大于开始时间！')
					return
				}

				if (data.interviewCount == 0 && timeDif > 60 * 60 * 1000) {
					if ((timeDif - 60 * 60 * 1000) > data.interviewMins * 60 * 1000) {
						ElMessage.error('您剩余的直播时长不够当前面试时间，请购买直播时长！')
						return
					}
				} else {
					if(data.interviewCount == 0){
						if (timeDif > 60 * 60 * 1000) {
							ElMessage.error('您剩余的直播时长不够当前面试时间，请购买直播时长！')
							return
						}
					}else{
						if (timeDif > data.interviewMins * 60 * 1000) {
							ElMessage.error('您剩余的直播时长不够当前面试时间，请购买直播时长！')
							return
						}
					}
					proxy.$http.get(`/index.php/Home/CompanyUser/inviteInterview?uid=${data.touid}&invite_uid=${data.myuid}&start_time=${startTimeStr}&end_time=${endTimeStr}`).then((res : any) => {
						data.custom = {
							data: {
								businessID: "text_link",
								text: "面试邀请",
								link: "xxx",
								time: startTimeStr1 + ' - ' + endTimeStr1,
								content: `${data.mynickname}邀请您参加面试`,
								myuid: data.myuid,
								touid: data.touid,
								interview_id: res.data.data.message
							},
							description: '',
							extension: '',
						};
						Custom.TUIServer.sendCustomMessage(data.custom);
						cancel();
					})
					
				}
			};

			const handleTimeChange = (value : any) => {
				console.log('value', value)
				if (value && value.length === 2) {
					const startTime = new Date(value[0]).getTime();
					const endTime = new Date(value[1]).getTime();
					const nowTime = new Date().getTime();
					if(startTime<nowTime){
						ElMessage.error('您选择的开始时间不能早于现在时间！')
						value1.value[0] = new Date()
					}
					if(startTime == endTime){
						ElMessage.error('您选择的面试结束时间需要大于开始时间！')
					}
				}
			}


			const user_live_buys = () => {
				// window.location.href = `https://vitadviser.com/user_live_buys.html?myuid=${data.myuid}&touid=${data.touid}`
				window.open(`https://vitadviser.com/user_live_buys.html?myuid=${data.myuid}&touid=${data.touid}`);
			}

			return {
				...toRefs(data),
				toggleShow,
				cancel,
				submit,
				value1,
				timeFilter,
				disabledDateFun,
				user_live_buys,
				handleTimeChange
			};
		},
	});
	export default Custom;
